/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router';
import SlidePlayer from '@/views/SlidePlayer.vue';
import ActivationCode from '@/views/ActivationCode.vue';
const routes = [
  {
    path: '/',
    name: 'ActivationCode',
    component: ActivationCode,
  },
  {
    path: '/slide',
    name: 'SlidePlayer',
    component: SlidePlayer,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
