var axios = require('axios');

// Create an axios instance with default configurations
var apiClient = axios.create({
    baseURL: 'https://api.hoomans.dev/petti', // http://localhost:8081/ https://api.hoomans.dev/petti 
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});  

module.exports = {
    get: function (endpoint) {
        return apiClient.get(endpoint)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                console.error('GET request failed:', error);
                throw error;
            });
    },
    post: function (endpoint, data) {
        return apiClient.post(endpoint, data)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                console.error('POST request failed:', error);
                throw error;
            });
    },
    delete: function (endpoint) {
        return apiClient.delete(endpoint)
            .then(function (response) {
                return response.data;
            })
            .catch( function (error) {
                console.error('DELETE request failed:', error);
                throw error;
            })
    }
};