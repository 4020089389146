<template>
  <div
    id="body"
    style="width: 100%; height: 100vh; background-color: black"
    class="noselect"
  >
    <div
      v-if="isUpdatingPlaylist"
      style="
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div style="width: 72px">
        <div class="spinner-square">
          <div class="square-1 square"></div>
          <div class="square-2 square"></div>
          <div class="square-3 square"></div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="currentSlide" 
        style="
          width: 100%; 
          height: 100vh; 
          position: relative;" 
        @click.stop="togglePlayerControls"
      >
        <DebugLogs v-if="showDebugBtn" ref="debugScreen"/>
        <div style="
          position: absolute; 
          top: 0px; 
          right: 0px; 
          width: 100%; 
          height: 100vh; 
          z-index: 998; 
          display: flex;
          justify-content: end;
          gap: 30px;"
        >
          <div v-if="showPlayerInfo && !showCachedUrls" style="width: 250px; height: auto; margin: 30px 0px 30px 30px;">
            <PlayerInfo :togglePlayerInfo="togglePlayerInfo" :toggleCachedUrls="toggleCachedUrls" :cachedUrls="cachedUrls"/>
          </div>
          <div v-if="showPlayerControls && !showCachedUrls" style="width: 300px; height: auto; margin: 30px 30px 30px 0px;">
            <PlayerControls :togglePlayerInfo="togglePlayerInfo" :togglePlayerControls="togglePlayerControls" :nextSlide="nextSlide" :prevSlide="prevSlide" :currentSlide="currentSlide" :videoDuration="videoDuration"/>
          </div>
          <div v-if="showCachedUrls" style="width: 100%; height: 100vh; display: flex; justify-content: center; align-items: center;">
            <div style="width: 50%; min-height: 300px; background-color: black; padding: 30px; display: flex; flex-direction: column; gap: 20px;">
              <div style="display: flex; justify-content: space-between;">
                <div>
                  <p style="color: azure;">cached urls</p>
                </div>
                <div>
                  <img @click.stop="toggleCachedUrls()" style="cursor: pointer;" src="../assets/close-line.svg" alt="">
                </div>
              </div>
              <div v-for="(item, index) in this.playlistStore.cachedUrls" :key="index">
                <p style="color: azure;">{{ item }}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="['slide', { 'slide-active': isActive }]"
          v-if="currentSlide.type === 'image'"
          :style="{
            width: '100%',
            height: '100vh',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          }"
        >
          <img
            :src="currentSlide.file"
            alt="Slide"
            v-if="currentSlide.type === 'image'"
            :style="{
              objectFit:
                currentSlide.layout === 'centered' ? 'contain' : 'cover',
              width: currentSlide.layout === 'centered' ? '100%' : '100%',
              height: currentSlide.layout === 'centered' ? '100%' : '100%',
            }"
            loading="eager"
          />
          <p
            v-if="currentSlide.caption"
            :style="{
              fontFamily: currentSlide.fontfamily,
              fontStyle: normal,
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: '32px',
              letterSpacing: '-0.015em',
              justifyContent: currentSlide.allignment,
              display: 'flex',
              borderRadius: '4px',
              backgroundColor:
                currentSlide.textBackground === ''
                  ? ''
                  : currentSlide.textBackground,
              color: currentSlide.fontColor,
              padding: '4px 16px',
              margin: '8px 0px',
              position: 'absolute',
              bottom: '4px',
            }"
          >
            {{ currentSlide.caption }}
          </p>
        </div>
        <div
          :class="['slide', { 'slide-active': isActive }]"
          v-else-if="currentSlide.type === 'text'"
          style="width: 100%; height: 100vh"
        >
          <div
            :style="{
              width: '100%',
              height: '100vh',
              backgroundColor: currentSlide.background,
              alignItems: currentSlide.allignment,
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              padding: '10.76vw 11.78vw',
              gap: '24px',
            }"
          >
            <div style="width: 100%">
              <p
                :style="{
                  padding: '8px 0px',
                  fontFamily: currentSlide.fontfamily,
                  color: currentSlide.fontColor,
                  fontSize: currentSlide.fontSize
                    ? filterBySize(currentSlide.fontSize).title
                    : '5vw',
                  fontWeight:
                    currentSlide.fontSize === ('m' || 'l') ? '700' : '600',
                  justifyContent:
                    currentSlide.allignment === 'end'
                      ? 'flex-end'
                      : currentSlide.allignment,
                  textAlign: currentSlide.allignment,
                  lineHeight: currentSlide.fontSize
                    ? filterBySize(currentSlide.fontSize).lineHeight
                    : '5.2vw',
                  display: 'flex',
                }"
              >
                {{ currentSlide.title }}
              </p>
            </div>
            <div style="width: 100%">
              <p
                :style="{
                  width: '100%',
                  paddingTop: '8px',
                  fontFamily: currentSlide.fontfamily,
                  color: currentSlide.fontColor,
                  fontSize: currentSlide.fontSize
                    ? filterBySize(currentSlide.fontSize).subtitle
                    : '3vw',
                  justifyContent:
                    currentSlide.allignment === 'end'
                      ? 'flex-end'
                      : currentSlide.allignment,
                  fontWeight: '400px',
                  textAlign: currentSlide.allignment,
                  lineHeight: currentSlide.fontSize
                    ? filterBySize(currentSlide.fontSize).sub_lineHeight
                    : '3.2vw',
                  display: 'flex',
                }"
              >
                {{ currentSlide.subtitle }}
              </p>
            </div>
          </div>
        </div>
        <div
          :class="['slide', { 'slide-active': isActive }]"
          v-else-if="currentSlide.type === 'birthday'"
          style="width: 100%; height: 100vh"
        >
          <div
            :style="{ alignItems: 'center', width: '100%', height: '100vh' }"
          >
            <div
              :style="{
                width: '100%',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                backgroundImage:
                  currentSlide.theme === 'image'
                    ? 'url(' + currentSlide.file + ')'
                    : 'url(' +
                      filterThemesByColor(currentSlide.theme).themes +
                      ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }"
            >
              <div
                :style="{
                  width: '100%',
                  height: '100vh',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }"
              >
                <div
                  :style="{
                    backgroundColor: filterThemesByColor(currentSlide.theme)
                      .Background,
                    padding: '24px 40px',
                  }"
                >
                  <p
                    class="title-text"
                    :style="{
                      fontFamily: currentSlide.fontfamily,
                      color: filterThemesByColor(currentSlide.theme).fontColor1,
                      fontSize: '4.27vw',
                      lineHeight: '4.76vw',
                      justifyContent: currentSlide.allignment,
                      display: 'flex',
                    }"
                  >
                    {{ currentSlide.messageTop }}
                  </p>
                  <p
                    class="title-text"
                    :style="{
                      fontFamily: currentSlide.fontfamily,
                      color: filterThemesByColor(currentSlide.theme).fontColor2,
                      fontSize: '3.53vw',
                      lineHeight: '3.90vw',
                      justifyContent: currentSlide.allignment,
                      display: 'flex',
                      padding: '20px 0px',
                    }"
                  >
                    {{ currentSlide.message }}
                  </p>
                  <p
                    class="title-text"
                    :style="{
                      fontFamily: currentSlide.fontfamily,
                      color: filterThemesByColor(currentSlide.theme).fontColor3,
                      fontSize: '1.88vw',
                      justifyContent: currentSlide.allignment,
                      lineHeight: '2.55vw',
                      display: 'flex',
                      maxWidth: '800px',
                    }"
                  >
                    {{ currentSlide.messageBottom }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="['slide', { 'slide-active': isActive }]"
          class="video-containers"
          v-else-if="currentSlide.type === 'video'"
          :style="{
            width: '100%',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }"
        >
          <video
            id="'slider-video"
            class="video-js vjs-fill"
            controls
            muted
            preload="auto"
            :style="{
              objectFit:
                currentSlide.layout === 'centered' ? 'contain' : 'cover',
              width: currentSlide.layout === 'centered' ? '100%' : '100%',
              height: currentSlide.layout === 'centered' ? '100%' : '100%',
            }"
            data-setup="{}"
            ref="videoPlayer"
            :options="videoOptions"
          >
            <source type="video/mp4" />
          </video>
          <div
            v-if="currentSlide.caption"
            :style="{
              backgroundColor:
                currentSlide.textBackground === ''
                  ? ''
                  : currentSlide.textBackground,
              margin: '4px 0px',
              padding: '4px 16px',
              position: 'absolute',
              bottom: '8px',
              zIndex: '999',
            }"
          >
            <p
              :style="{
                fontFamily: currentSlide.fontfamily,
                fontStyle: normal,
                fontWeight: 600,
                fontSize: '24px',
                lineHeight: '32px',
                letterSpacing: '-0.015em',
                justifyContent: currentSlide.allignment,
                display: 'flex',
                borderRadius: '4px',
                backgroundColor:
                  currentSlide.textBackground === ''
                    ? ''
                    : currentSlide.textBackground,
                color: currentSlide.fontColor,
              }"
            >
              {{ currentSlide.caption }}
            </p>
          </div>
        </div>
        <div
          :class="['slide', { 'slide-active': isActive }]"
          v-else-if="currentSlide.type === 'embed'"
          style="width: 100%; height: 100vh"
        >
          <div
            :style="{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              objectFit: 'cover',
              width: '100%',
              height: '100vh',
              cursor: 'none',
            }"
          >
            <div v-if="isEmbedUrl(currentSlide.code)" class="embed-container">
              <iframe
                :src="embedUrl(currentSlide.code)"
                frameborder="0"
                allow="autoplay"
                allowfullscreen
                muted
              ></iframe>
            </div>
            <div
              v-else-if="isIframeCode(currentSlide.code)"
              class="embed-container"
            >
              <div v-html="currentSlide.code"></div>
            </div>
          </div>
        </div>
        <div
          :class="['slide', { 'slide-active': isActive }]"
          v-else-if="currentSlide.type === 'number'"
          style="width: 100%; height: 100vh"
        >
          <div
            :style="{
              width: '100%',
              height: '100vh',
              padding: '40px',
              backgroundColor: currentSlide.background,
              alignItems: currentSlide.allignment,
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }"
          >
            <div
              :style="{
                alignItems: currentSlide.allignment,
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }"
            >
              <div style="padding: 0px 24px">
                <div>
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <div style="padding: 0px 5px">
                      <p
                        :style="{
                          fontFamily: currentSlide.fontfamily,
                          color: currentSlide.fontColor,
                          fontSize: '6vw',
                          lineHeight: '6.2vw',
                          fontWeight: 500,
                          justifyContent: currentSlide.allignment,
                          textAlign: currentSlide.allignment,
                          display: 'flex',
                        }"
                      >
                        {{ currentSlide.number }}
                      </p>
                    </div>
                  </div>
                  <div style="padding: 0px 15px">
                    <p
                      :style="{
                        fontFamily: currentSlide.fontfamily,
                        color: currentSlide.fontColor,
                        fontSize: '3vw',
                        lineHeight: '3.2vw',
                        fontWeight: 500,
                        justifyContent: currentSlide.allignment,
                        textAlign: currentSlide.allignment,
                        display: 'flex',
                      }"
                    >
                      {{ currentSlide.about }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var themes = require("../libraries/ColorThemes");
var font = require("../libraries/fontSize");
import PlayerControls from "@/components/PlayerControls.vue";
import { usePlaylistStore } from "../store/PlaylistStore";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import PlayerInfo from "@/components/PlayerInfo.vue";
import DebugLogs from "@/components/DebugLogs.vue";

export default {
  name: "PlayerComponent",
  components: {
    PlayerControls,
    PlayerInfo,
    DebugLogs,
  },
  setup: function () {
    var playlistStore = usePlaylistStore();

    return {
      playlistStore: playlistStore,
      PlayerControls,
      PlayerInfo,
      DebugLogs
    };
  },
  data: function () {
    return {
      progress: {},
      isLoading: false,
      timer: null,
      videoOptions: {
        controls: false,
        autoplay: true,
        sources: [],
        muted: true,
        preload: "auto",
      },
      currentSlideIndex: null,
      slideInterval: null,
      playlistData: [],
      isActive: true,
      videoDuration: 0,
      defaultTime: 6000,
      targetId: "body",
      inactivityTimeout: 2000,
      timeout: null,
      isUpdatingPlaylist: false,
      isVideoInitialized: false,
      fileUrl: "",
      videoUrl: "",
      orientation: "",
      screenResolution: "",
      aspectRatio: "",
      screenRotation: "",
      agent: "",
      memory:"",
      showPlayerControls: false,
      showPlayerInfo: false,
      showCachedUrls: false,
      showDebugBtn:false,
    };
  },

  computed: {
    playlist: function () {
      return this.playlistData;
    },
    currentSlide: function () {
      return this.playlist[this.currentSlideIndex] || null;
    },
    slideDuration: function () {
      if (this.currentSlide) {
        if (this.currentSlide.type === "video") {
          return this.videoDuration;
        }
      }
      return this.defaultTime;
    },
  },
  watch: {
    playlistData: {
      immediate: true,
      handler(newPlaylist) {
        if (newPlaylist.length > 0) {
          this.handlePlaylistUpdate(newPlaylist);
        }
      },
      deep: true,
    },
    currentSlide: {
      immediate: true,
      handler: function (newSlide) {
        var self = this;
        if (newSlide && newSlide.type === "video") {
          self.$nextTick(function () {
            self.initVideoPlayer(newSlide);
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    toggleCachedUrls: function (){
      this.showCachedUrls = !this.showCachedUrls;
    },
    togglePlayerInfo: function() {
      this.showPlayerInfo = !this.showPlayerInfo; 
    },
    togglePlayerControls: function() {
      this.showPlayerControls = !this.showPlayerControls;
      this.showPlayerInfo = false; 
      this.showDebugBtn = !this.showDebugBtn;
    },
    handleOrientation: function(){
      if (window.matchMedia("(orientation: portrait)").matches) {
        this.orientation = 'Portrait'
      }else if (window.matchMedia("(orientation: landscape)").matches) {
        this.orientation = 'Landscape'
      }
    },
    handlePlaylistUpdate: function(newPlaylist) {
      var self = this;
      this.stopCurrentPlayback();
      this.isUpdatingPlaylist = true;
      caches.keys().then(function (cacheNames) {
        cacheNames.forEach(function (cacheName) {
          caches.delete(cacheName);
        });
      });
      this.playlistData = newPlaylist;
      setTimeout(function () {
        self.preloadMedia();
        setTimeout(function () {
          self.startNewPlaylist();
        }, 0); // 6000
      }, 100); // 4000
    },
    stopCurrentPlayback: function() {
      this.stopSlideShow();
      clearTimeout(this.timer);

      if (this.currentSlide) {
        if (this.currentSlide.type === "video") {
          this.safeDisposeVideoPlayer();
        } else {
          clearTimeout(this.slideInterval);
        }
      }
    },
    preloadMedia: function () {
      if (navigator.serviceWorker.controller) {
        var promises = this.playlist
          .filter(function (item) {
            return item.file;
          })
          .map(function (item) {
            return item.file;
          });

        navigator.serviceWorker.controller.postMessage({
          type: "CACHE_MEDIA",
          preloads: promises,
        });
      } else {
        window.location.reload();
        console.warn("Service Worker not ready for preloading.");
      }
    },
    safeDisposeVideoPlayer: function (callback) {
      var self = this;
      if (self.player) {
        try {
          self.player.pause();
          self.player.dispose();
          var videoElement = self.$refs.videoPlayer;
          if (videoElement && videoElement.parentNode) {
            videoElement.parentNode.removeChild(videoElement);
          }
          self.isVideoInitialized = false;
        } catch (error) {
          console.error("Error disposing player:", error);
        }
        self.player = null;
      }
      if (typeof callback === "function") {
        callback();
      }
    },
    stopVideoPlayback: function () {
      var videoElement = this.$refs.videoPlayer;
      if (videoElement && typeof videojs !== "undefined") {
        var player = videojs.getPlayer(videoElement);
        if (player) {
          player.pause();
          player.dispose();
        }
      }
    },
    startNewPlaylist: function() {
      var self = this;
      this.currentSlideIndex = 0;

      if (this.playlist.length > 0) {
        setTimeout(function () {
          if (self.currentSlide && self.currentSlide.type === "video") {
            self.initVideoPlayer(self.currentSlide);
            setTimeout(function () {
              self.isUpdatingPlaylist = false;
            }, 0);
          } else {
            self.startTimer();
            self.isUpdatingPlaylist = false;
          }
        }, 0);
      } else {
        console.error("No slides in the new playlist");
      }

      this.isUpdatingPlaylist = false;
    },
    startTimer: function() {
      var self = this;
      clearTimeout(this.timer);
      if (this.currentSlide && this.currentSlide.type !== "video") {
        this.timer = setTimeout(function () {
          self.nextSlide();
        }, this.currentSlide.duration);
      }
    },

    stopSlideShow: function() {
      clearInterval(this.slideInterval);
    },

    initVideoPlayer: function(videoSrc) {
      var self = this;
      this.videoDuration = 0;
      this.safeDisposeVideoPlayer();

      this.$nextTick(function () {
        var existingVideoElement = self.$refs.videoPlayer;
        if (existingVideoElement && existingVideoElement.parentNode) {
          existingVideoElement.parentNode.removeChild(existingVideoElement);
        }

        var newVideoElement = document.createElement("video");
        newVideoElement.ref = "videoPlayer";
        newVideoElement.className = "video-js";
        newVideoElement.style.width = "100%";
        newVideoElement.style.height = "100%";
        newVideoElement.style.objectFit =
          videoSrc.layout === "centered" ? "contain" : "cover";
        newVideoElement.style.position = "relative";
        self.$refs.videoPlayer = newVideoElement;

        var containerElement = document.querySelector(".video-containers");
        if (containerElement) {
          containerElement.appendChild(newVideoElement);

          try {
            self.player = videojs(
              newVideoElement,
              {
                controls: false,
                autoplay: true,
                muted: true,
                sources: [{ src: videoSrc.file }],
                preload: "auto",
              },
              function () {
                self.videoDuration = this.duration() * 1000;
                this.play().catch(function (error) {
                  console.error("Video playback failed:", error);
                });
              }
            );

            self.player.on("start", function () {
              console.log(self.player.liveTracker.seekableEnd(),"player"); 
            });

            self.player.on("ended", function () {
              self.nextSlide();
            });

            self.isVideoInitialized = true;
          } catch (error) {
            console.error("Error initializing video player:", error);
          }
        } else {
          console.error("Video container not found");
        }
      });
    },

    nextSlide: function() {
      var self = this;
      if (this.isUpdatingPlaylist) return;
      this.isActive = false;
      this.stopCurrentPlayback();

      var nextSlideIndex = (this.currentSlideIndex + 1) % this.playlist.length;
      var nextSlide = this.playlist[nextSlideIndex];
      if (nextSlide) {
        if (nextSlide.type === "image") {
          self.loadImageSlide(nextSlide, nextSlideIndex);
        } else if (nextSlide.type === "video") {
          self.loadVideoSlide(nextSlide, nextSlideIndex);
        } else {
          this.loadOtherSlide(nextSlideIndex);
        }
      } else {
        console.error("Invalid next slide");
      }
    },

    prevSlide: function () {
      var self = this;
      if (this.isUpdatingPlaylist) return;
      this.isActive = false;
      this.stopCurrentPlayback();

      var nextSlideIndex = (this.currentSlideIndex - 1 + this.playlist.length) % this.playlist.length;
      var nextSlide = this.playlist[nextSlideIndex];
      if (nextSlide) {
        if (nextSlide.type === "image") {
          self.loadImageSlide(nextSlide, nextSlideIndex);
        } else if (nextSlide.type === "video") {
          self.loadVideoSlide(nextSlide, nextSlideIndex);
        } else {
          this.loadOtherSlide(nextSlideIndex);
        }
      } else {
        console.error("Invalid next slide");
      }
    },

    loadImageSlide: function (slide, index) {
      var self = this;
      var img = new Image();
      img.src = slide.file;
      img.onload = function () {
        setTimeout(function () {
          self.currentSlideIndex = index;
          self.isActive = true;
          self.startTimer();
        }, 10);
      };
    },

    loadThemeSlide: function (slide, index) {
      var self = this;
      var img = new Image();
      img.src = slide.file;
      img.onload = function () {
        setTimeout(function () {
          self.currentSlideIndex = index;
          self.isActive = true;
          self.startTimer();
        }, 10);
      };
    },

    loadVideoSlide: function (slide, index) {
      var self = this;
      var video = document.createElement("video");
      video.src = slide.file;
      video.preload = "auto";
      video.onloadeddata = function () {
        setTimeout(function () {
          self.currentSlideIndex = index;
          self.isActive = true;
          self.initVideoPlayer(slide);
        }, 10);
      };
      video.onerror = function (error) {
        console.error("Error loading video:", error);
        self.nextSlide();
      };
    },

    loadOtherSlide: function (index) {
      var self = this;
      setTimeout(function () {
        self.currentSlideIndex = index;
        self.isActive = true;
        self.startTimer();
      }, 10);
    },

    fadeOutCurrentSlide: function () {
      var currentSlideElement = this.$el.querySelector(".slide-active");
      if (currentSlideElement) {
        currentSlideElement.classList.remove("slide-active");
      }
    },

    fadeInCurrentSlide: function () {
      var newSlideElement =
        this.$el.querySelectorAll(".slide")[this.currentSlideIndex];
      if (newSlideElement) {
        newSlideElement.classList.add("slide-active");
      }
    },
    filterThemesByColor: function(color) {
      for (var i = 0; i < themes.default.length; i++) {
        if (themes.default[i].color === color) {
          return themes.default[i];
        }
      }
      return null;
    },
    filterBySize: function(size) {
      for (var i = 0; i < font.default.length; i++) {
        if (font.default[i].size === size) {
          return font.default[i];
        }
      }
      return null;
    },
    isEmbedUrl: function(url) {
      try {
        new URL(url);
        return true;
      } catch (_) {
        return false;
      }
    },
    embedUrl: function(url) {
      return this.isEmbedUrl(url) ? this.getEmbedUrl(url) : "";
    },

    getEmbedUrl: function(url) {
      let embedUrl = url;
      const youtubeRegex =
        /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/i;
      const spotifyRegex =
        /open\.spotify\.com\/(track|album|playlist)\/([^?]+)/;

      let match = url.match(youtubeRegex);
      if (match && match[1]) {
        return `https://www.youtube.com/embed/${match[1]}?autoplay=1&mute=1&cc_load_policy=1`;
      }

      match = url.match(spotifyRegex);
      if (match && match[1] && match[2]) {
        return `https://open.spotify.com/embed/${match[1]}/${match[2]}`;
      }

      return embedUrl;
    },
    isIframeCode: function(code) {
      return code.trim().startsWith("<iframe");
    },
    checkForPlaylistUpdates: function() {
      const latestPlaylist = this.playlistStore.playlist.slides;
      if (
        JSON.stringify(latestPlaylist) !== JSON.stringify(this.playlistData)
      ) {
        this.playlistData = latestPlaylist;
      }
    },
    showCursor: function() {
      this.targetElement.classList.add("show-cursor");
      this.targetElement.classList.remove("hide-cursor");
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.hideCursor, this.inactivityTimeout);
    },
    hideCursor: function() {
      this.targetElement.classList.remove("show-cursor");
      this.targetElement.classList.add("hide-cursor");
    },
  },
  mounted: function () {
    var self = this;
    this.handleOrientation();
    // this.playlistData = JSON.parse(localStorage.getItem("playlist"));
    // self.startTimer();
    // self.startNewPlaylist();
    self.checkForPlaylistUpdates();
    setInterval(function () {
      self.checkForPlaylistUpdates();
    }, 3000);

    this.targetElement = document.getElementById(this.targetId);

    this.targetElement.addEventListener("mousemove", this.showCursor);
    this.targetElement.addEventListener("keydown", this.showCursor);

    window.addEventListener("resize", this.handleOrientation);
    window.addEventListener("orientationchange", this.handleOrientation);

    this.hideCursor();
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data && event.data.type === "CACHE_COMPLETE") {
          console.log("CACHE COMPLETED");
        }
      });
    }
  },

  beforeUnmount: function () {
    this.stopSlideShow();
    this.safeDisposeVideoPlayer();
    if (this.player) {
      try {
        this.player.dispose();
      } catch (error) {
        console.error("Error in beforeUnmount:", error);
      }
    }
    this.targetElement.removeEventListener("mousemove", this.showCursor);
    this.targetElement.removeEventListener("keydown", this.showCursor);

    window.removeEventListener("resize", this.handleOrientation);
    window.removeEventListener("orientationchange", this.handleOrientation);
  },
};
</script>

<style>
p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.title-text {
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-weight: 700;
  letter-spacing: -0.4px;
}

.subtitle-text {
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-weight: 500;
  letter-spacing: -0.2px;
}

.embed-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  cursor: none;
}

.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  cursor: none;
}

.noselect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.show-cursor {
  cursor: auto;
}
.hide-cursor {
  cursor: none;
}
.spinner-square {
  display: flex;
  flex-direction: row;
  width: 22px;
  height: 30px;
}

.spinner-square > .square {
  width: 6px;
  height: 20px;
  margin: auto auto;
  border-radius: 2px;
}

.square-1 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s
    infinite;
}

.square-2 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 200ms
    infinite;
}

.square-3 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 400ms
    infinite;
}

@keyframes square-anim {
  0% {
    height: 20px;
    background-color: #335cff;
  }
  20% {
    height: 20px;
  }
  40% {
    height: 30px;
    background-color: #5d7eff;
  }
  80% {
    height: 20px;
  }
  100% {
    height: 20px;
    background-color: #2a49cc;
  }
}
</style>
