<script>
import { ref } from "vue";
import http from "../networkUtils/networkUtil";
import { usePlaylistStore } from "../store/PlaylistStore";
import SlidePlayer from "./SlidePlayer.vue";
import mixpanel from "mixpanel-browser";
import DebugLogs from "@/components/DebugLogs.vue";

export default {
  name: "ActivationCode",
  created() {
    mixpanel.track("TV connect screen viewed");
  },
  components: {
    SlidePlayer,
    DebugLogs
  },
  setup: function () {
    var playlistStore = usePlaylistStore();
    const connected = ref(false);

    return {
      playlistStore: playlistStore,
      SlidePlayer,
      connected,
      DebugLogs
    };
  },
  data: function () {
    return {
      ws: null,
      data: null,
      clientId: "ClientA",
      uuid: null,
      codes: [],
      targetId: "connection",
      inactivityTimeout: 2000,
      timeout: null,
      retryCount: 0,
      maxRetries: 1,
      retryDelay: 1000,
      isOnline: navigator.onLine,
      showDebugBtn:false,
    };
  },
  mounted: function () {
    var self = this;
    this.updateNetworkStatus();
    this.setupWakeLockListener();
    this.requestWakeLock();
    this.clearStorage();
    this.playlistStore.debugLog("activation code")
    const storedConnected = localStorage.getItem("connected");
    if (storedConnected === "true") {
      this.connected = true;
    }
    if (localStorage.getItem("CodeUUID")) {
      this.clientId = localStorage.getItem("Code");
      this.uuid = localStorage.getItem("CodeUUID");
      this.codes = this.clientId.split("");
      this.connectWebSocket();
      http.get(`/tv/screen/fetch/${this.uuid}`).then((data)=>{
        this.screen = data.data;
        this.fetchSlides(this.screen.play_list)
      }).catch((err)=>{
        console.log(err)
        if(self.connected === true){
          localStorage.removeItem("CodeUUID");
          localStorage.removeItem("Code");
          self.connected = false;
          localStorage.setItem("connected", "false");
          caches.keys().then((cacheNames) => {
            cacheNames.forEach((cacheName) => {
              caches.delete(cacheName);
            });
          });
          location.reload();
        }
      })
    } else {
      this.fetchData();
    }
    this.targetElement = document.getElementById(this.targetId);

    this.targetElement.addEventListener("mousemove", this.showCursor);
    this.targetElement.addEventListener("keydown", this.showCursor);

    window.addEventListener("online", this.updateNetworkStatus);
    window.addEventListener("offline", this.updateNetworkStatus);

    this.hideCursor();
  },
  unmounted: function () {
    this.targetElement.removeEventListener("mousemove", this.showCursor);
    this.targetElement.removeEventListener("keydown", this.showCursor);

    window.removeEventListener("online", this.updateNetworkStatus);
    window.removeEventListener("offline", this.updateNetworkStatus);
  },
  watch: {
    isOnline(newStatus, oldStatus) {
      if (!oldStatus && newStatus) {
        location.reload();
      }
    },
  },
  methods: {
    connectWebSocket: function () {
      var self = this;
      this.ws = new WebSocket("wss://api.hoomans.dev/ws?id=" + this.clientId); //api.hoomans.dev localhost:8081

      this.ws.onopen = function () {
        console.log("WebSocket connection established");
        self.playlistStore.debugLog("WebSocket connection established")
        self.retryCount = 0;
      };

      this.ws.onmessage = function (event) {
        var eventData = JSON.parse(event.data);
        if (eventData["message"] === "SCREEN_CONNECTED") {
          playlistID = eventData["play_list_id"];
          self.playlistStore.debugLog("SCREEN_CONNECTED")
          self.fetchSlides(playlistID).then(function () {
            self.connected = true;
            localStorage.setItem("connected", "true");
          });
        } else if (eventData["message"] === "UPDATE_PLAYLIST") {
          var playlistID = eventData["play_list_id"];
          self.playlistStore.debugLog("UPDATE_PLAYLIST")
          self.fetchSlides(playlistID).then(function () {
            console.log("successfully fetched");
          });
        } else if (eventData["message"] === "CHANGE_PLAYLIST") {
          playlistID = eventData["play_list_id"];
          self.playlistStore.debugLog("CHANGE_PLAYLIST")
          self.fetchSlides(playlistID).then(function () {
            console.log("successfully fetched");
          });
        } 
        // else if (eventData["message"] === "SCREEN_DISCONNECTED") {
        //   playlistID = eventData["play_list_id"];
        //   self.connected = false;
        //   localStorage.removeItem("connected");
        //   // localStorage.removeItem("playlist");
        //   self.playlistStore.debugLog("SCREEN_DISCONNECTED")
        // } else if (eventData["message"] === "SCREEN_RECONNECTED") {
        //   playlistID = eventData["play_list_id"];
        //   self.playlistStore.debugLog("SCREEN_RECONNECTED")
        //   self.fetchSlides(playlistID).then(function () {
        //     self.connected = true;
        //     localStorage.setItem("connected", "true");
        //     self.requestWakeLock();
        //   });
        // } 
        else if (eventData["message"] === "SCREEN_DELETED") {
          // localStorage.removeItem("playlist");
          localStorage.removeItem("CodeUUID");
          localStorage.removeItem("Code");
          self.connected = false;
          localStorage.setItem("connected", "false");
          self.fetchData();
          self.$router.push("/");
          caches.keys().then((cacheNames) => {
            cacheNames.forEach((cacheName) => {
              caches.delete(cacheName);
            });
          });
          self.playlistStore.debugLog("SCREEN_DELETED")
        }
      };

      this.ws.onerror = function (error) {
        console.error("WebSocket error:", error);
        self.playlistStore.debugLog("WebSocket error:");
        self.playlistStore.debugLog(error);
      };

      this.ws.onclose = function () {
        console.log("WebSocket connection closed");
        self.playlistStore.debugLog("WebSocket connection closed");
        if (self.retryCount < self.maxRetries) {
          self.retryCount++;
          console.log(`Reconnecting ${self.retryCount}`);
          self.playlistStore.debugLog(`Reconnecting ${self.retryCount}`);
          setTimeout(self.connectWebSocket(), self.retryDelay);
        } else {
          console.log("Max reconnection attempts reached");
          self.playlistStore.debugLog("Max reconnection attempts reached");
        }
      };
    },
    fetchSlides: function (playlistID) {
      var self = this;
      return new Promise(function (resolve, reject) {
        http
          .get(`/playlist/getslides?ID=${playlistID}`)
          .then(function (data) {
            if (data["status"] === "success") {
              var slides = data["data"];
              var formatSlides = slides.map(function (slide) {
                return {
                  caption: slide.caption,
                  title: slide.title || "",
                  playlist_id: parseInt(playlistID),
                  type: slide.type,
                  subtitle: slide.sub_title || "",
                  file: slide.file || "",
                  fontfamily: slide.font_family,
                  fontColor: slide.font_color,
                  fontSize: slide.font_size || "",
                  fontSizeTitle: slide.font_size_title || "",
                  allignment: slide.alignment,
                  justify: slide.justify,
                  background: slide.background_color,
                  layout: slide.layout,
                  content: slide.url || "",
                  message: slide.br_message || "",
                  messageTop: slide.br_message_top || "",
                  messageBottom: slide.br_message_bottom || "",
                  theme: slide.br_theme || "",
                  number: parseInt(`${slide.number_type}`) || "",
                  order: slide.order,
                  timer: 1,
                  id: slide.id,
                  code: slide.emb_code,
                  about: slide.nu_about,
                  duration: slide.duration || "",
                  textBackground: slide.text_bg || "",
                  fileType: slide.file_type || "",
                  fileName: slide.file_name || ""
                };
              });
              self.playlistStore.$state.playlist.slides = formatSlides;
              // localStorage.setItem(
              //   "playlist",
              //   JSON.stringify(self.playlistStore.$state.playlist.slides)
              // );
              resolve();
            }
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    fetchData: function () {
      var self = this;
      http
        .post("/createScreen", {})
        .then(function (data) {
          self.data = data;
          self.clientId = data["data"]["activation_code"];
          self.uuid = data["data"]["uuid"];
          localStorage.setItem("CodeUUID", self.uuid);
          localStorage.setItem("Code", self.clientId);
          self.codes = self.clientId.split("");
          self.connectWebSocket();
        })
        .catch(function (error) {
          console.error("Error fetching data:", error);
          self.playlistStore.debugLog(`Error fetching data: ${error}`);
        });
    },
    showCursor() {
      this.targetElement.classList.add("show-cursor");
      this.targetElement.classList.remove("hide-cursor");
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.hideCursor, this.inactivityTimeout);
    },
    hideCursor() {
      this.targetElement.classList.remove("show-cursor");
      this.targetElement.classList.add("hide-cursor");
    },
    clearStorage() {
      let session = localStorage.getItem("connected");
      if (session === "true") {
        localStorage.setItem("connected", "true");
      } else {
        localStorage.setItem("connected", "false");
      }
    },
    requestWakeLock: function () {
      var wakelock;
      if ("wakeLock" in navigator) {
        navigator.wakeLock
          .request("screen")
          .then(function (lock) {
            wakelock = lock;
            wakelock.addEventListener("release", function () {
              console.log("Screen Wake State Locked:", !wakelock.released);
            });
            console.log("Screen Wake State Locked:", !wakelock.released);
          })
          .catch(function (e) {
            console.error("Failed to lock wake state", e.message);
          });
      } else {
        console.warn("Wake Lock API not supported on this browser.");
      }
    },
    setupWakeLockListener: function () {
      var self = this;
      document.addEventListener("visibilitychange", function () {
        if (document.visibilityState === "visible") {
          self.requestWakeLock();
        }
      });
    },
    updateNetworkStatus() {
      var self = this;
      self.isOnline = navigator.onLine;
      console.log("network", this.isOnline);
    },
    showDebugBtnCntrl: function(){
      this.showDebugBtn = !this.showDebugBtn;
    }
  },
};
</script>

<template>
  <div @click.stop="showDebugBtnCntrl">
    <div v-if="!connected " id="connection" class="activation-box">
      <div
        style="
          width: 100%;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
        "
        class="noselect"
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 42px;
          "
        >
          <div>
            <img src="../assets/Lyst-logo-white-bg.svg" alt="" />
          </div>
          <div class="inner-box">
            <div style="padding: 8px">
              <p class="name">Let's connect your screen</p>
              <p class="code-sub">Enter this code on your desktop</p>
            </div>
            <div class="flex flex-wrap">
              <div
                v-for="(item, index) in codes"
                :key="index"
                class="code"
                style="
                  width: 76px;
                  height: 76px;
                  border-radius: 8px;
                  background-color: #00d54b;
                  border: 1px solid #00d54b;
                  margin: 0px 8px;
                "
              >
                <p style="color: #ffffff">{{ item }}</p>
              </div>
            </div>
            <div class="link" style="padding: 8px 0px">
              <p style="letter-spacing: 0.2px">
                Visit
                <span class="underline">lyst.so</span> on your desktop browser
                to activate this screen
              </p>
            </div>
          </div>
          <div
            style="
              padding: 24px 0px;
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <p class="no-account">© 2024 lyst.so</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="playlistStore.playlist.slides.length === 0" style="width: 100%; height: 100vh; background-color: black;"></div>
      <SlidePlayer v-else/>
    </div>
    <DebugLogs v-if="showDebugBtn" ref="debugScreen"/>
  </div>
</template>

<style scoped>
.name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.02em;
  font-feature-settings: "cv09" on, "ss11" on, "calt" off, "liga" off;
  color: #000000;
  padding-bottom: 6px;
}
.code-sub {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #0e121b;
}
.activation-box {
  width: 100%;
  height: 100vh;
  background: #03d548;
  background-image: url("../assets/line-animation.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.inner-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 48px;
  gap: 48px;
  width: 713px;
  height: 427px;
  background: #ffffff;
  border: 1px solid #ffffff;
  backdrop-filter: blur(3.6px);
  border-radius: 16px;
}
.code {
  display: flex;
  align-items: center;
  justify-content: center;
}
.code p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  width: 617px;
  height: 64px;
  border-radius: 12px;
}
.link p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #989898;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.underline {
  color: black;
  font-weight: 600;
}
.noselect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.show-cursor {
  cursor: auto;
}
.hide-cursor {
  cursor: none;
}
.no-account {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
</style>
