<script>
import { usePlaylistStore } from "../store/PlaylistStore";
import http from "../networkUtils/networkUtil";
import DeleteScreenModal from "./DeleteScreenModal.vue";

export default {
    name: "PlayerInfo",
    props: ['togglePlayerInfo','toggleCachedUrls','cachedUrls'],
    components: {
        DeleteScreenModal,
    },
    mounted: function(){
        this.getScreenDetails();
        this.getPlaylistName();
        this.handleDimensions();
        this.handleAspectRatio();
        this.handleOrientation();
        this.handleRotation();
        this.handleAgent();
        setInterval(() => {
            this.handleMemoryInfo();
        }, 1000);
        this.getCachedUrls();
    },
    setup: function () {
        var playlistStore = usePlaylistStore();

        return {
            playlistStore: playlistStore,
            DeleteScreenModal,
        };
    },
    data: function(){
        return{
            dimensions: '',
            aspectratio: '',
            orientation: '',
            memory: '0 MiB | 0 MiB | 0 GiB',
            rotation: '',
            agent: '',
            screen: '',
            showDeleteModal: false,
            screenName: '',
            playlistName: '',
        }
    },
    methods: {
        handleDimensions: function(){
            let screenWidth = window.innerWidth;
            let screenHeight = window.innerHeight;
            this.dimensions = screenWidth +'x'+ screenHeight
        },
        handleAspectRatio: function(){
            let screenWidth = window.innerWidth;
            let screenHeight = window.innerHeight;
            let aspectRatio = (screenWidth / screenHeight).toFixed(2);
            if (aspectRatio > 1.77) {
                this.aspectratio = 'Widescreen'
            } else {
                this.aspectratio = 'Standard'
            }
        },
        handleOrientation: function(){
            let screenOrientation = window.screen.orientation.type; 
            this.orientation = screenOrientation; 
        },
        handleRotation: function(){
            let screenRotation = screen.orientation.angle;  
            if(screenRotation === 0){
                this.rotation = 'Normal (0deg)';
            } else if(screenRotation === 90){
                this.rotation = 'Clockwise (90deg)';
            } else if(screenRotation === 180){
                this.rotation = 'Upside Down (180deg)';
            } else if(screenRotation === 270){
                this.rotation = 'Counter Clockwise (270deg)';
            }
        },
        handleAgent: function() {
            let userAgent = navigator.userAgent;
            this.agent = userAgent;
        },
        handleMemoryInfo: function() {
            let memoryInfo = "Not available"; 
            if (performance.memory) {
                let usedMemory = (performance.memory.usedJSHeapSize / 1024 / 1024).toFixed(2);  
                let totalMemory = (performance.memory.totalJSHeapSize / 1024 / 1024).toFixed(2);  
                let memoryLimit = (performance.memory.jsHeapSizeLimit / 1024 / 1024 / 1024).toFixed(2);  
                memoryInfo = `${usedMemory} MiB | ${totalMemory} MiB | ${memoryLimit} GiB`;
                this.memory = memoryInfo;
            } else {
                memoryInfo = `0 MiB | 0 MiB | 0 GiB`;
                this.memory = memoryInfo;
            }
        },
        getCachedUrls: async function() {
            if ('caches' in window) {
                const cacheNames = await caches.keys(); 
                for (const cacheName of cacheNames) {
                    const cache = await caches.open(cacheName);
                    const cachedRequests = await cache.keys(); 
                    cachedRequests.forEach((request) => {
                        if(request.url.startsWith('https://petti-tv.s3.ap-south-1.amazonaws.com') && !this.playlistStore.cachedUrls.includes(request.url)){
                            this.playlistStore.cachedUrls.push(request.url)
                        }
                    }); 
                }
            } else {
                console.log("Caches are not supported in this browser.");
            }
        },
        removeCatchedUrls: function(){
            if ('caches' in window) {
                caches.keys().then((cacheNames) => {
                    cacheNames.forEach((cacheName) => {
                        caches.delete(cacheName);
                    });
                });
                this.playlistStore.cachedUrls.splice(0, this.playlistStore.cachedUrls.length);
            }
        },
        openDeleteModal: function() {
            let uuid = localStorage.getItem('CodeUUID');
            http.get(`/tv/screen/fetch/${uuid}`).then((data)=>{
                this.screen = data.data;
                this.showDeleteModal = true;
            }).catch((err)=>{
                console.log(err)
                this.showDeleteModal = true;
            })
        },
        deleteScreen: function (){
            this.showDeleteModal = false;
        },
        forceDeleteScreen: function (){
            this.showDeleteModal = false;
            localStorage.removeItem("CodeUUID");
            localStorage.removeItem("Code");
            localStorage.setItem("connected", "false");
            location.reload();
        },
        getScreenDetails: function(){
            let uuid = localStorage.getItem('CodeUUID');
            http.get(`/tv/screen/fetch/${uuid}`).then((data)=>{
                this.screen = data.data;
                this.screenName = this.screen.name;
            }).catch((err)=>{
                console.log(err)
            })
        },
        getPlaylistName: function() {
            var playlist = this.playlistStore.playlist.slides; 
            if (playlist && playlist.length > 0) {
                var playlistID = playlist[0].playlist_id;
                http.get(`/playlist/getname/${playlistID}`).then((data)=>{
                    this.playlistName = data.data;
                })
            } else {
                console.log('Playlist is empty or not valid');
            }
        },
    }
} 
</script>
<template>
    <div class="screen-info-main">
        <div class="screen-info-top">
            <div style="display: flex; flex-direction: column; gap: 4px;">
                <p class="title">Screen</p>
                <p class="value">{{ screenName === '' ? 'Untitled' : screenName }}</p>
            </div>
            <div>
                <img @click.stop="togglePlayerInfo()" style="cursor: pointer;" src="../assets/close-line.svg" alt="">
            </div>
        </div>
        <hr style="background-color: #989898; border: none; height: 1px;">
        <div class="screen-info-center">
            <div style="display: flex; flex-direction: column; gap: 4px;">
                <p class="title">Playlist name:</p>
                <p class="value">{{ playlistName ? playlistName : 'Untitled' }}</p>
            </div>
            <div style="display: flex; flex-direction: column; gap: 4px;">
                <p class="title">Player versions:</p>
                <p class="value">0.0.0.0</p>
            </div>
            <div style="display: flex; flex-direction: column; gap: 4px;">
                <p class="title">Screen dimensions:</p>
                <p class="value">{{ dimensions }}</p>
            </div>
            <div style="display: flex; flex-direction: column; gap: 4px;">
                <p class="title">Screen aspect ratio:</p>
                <p class="value">{{aspectratio}}</p>
            </div>
            <div style="display: flex; flex-direction: column; gap: 4px;">
                <p class="title">Screen orientation:</p>
                <p class="value">{{orientation}}</p>
            </div>
            <div style="display: flex; flex-direction: column; gap: 4px;">
                <p class="title">Screen rotation:</p>
                <p class="value">{{ rotation }}</p>
            </div>
            <div style="display: flex; flex-direction: column; gap: 4px;">
                <p class="title">Agent:</p>
                <p class="value">{{agent}}</p>
            </div>
            <div style="display: flex; flex-direction: column; gap: 4px;">
                <p class="title">Memory:</p>
                <p class="value">{{memory}}</p>
            </div>
            <div style="display: flex; flex-direction: column; gap: 4px;">
                <p class="title">Cache:</p>
                <div style="display: flex; gap: 4px;">
                    <button @click.stop="toggleCachedUrls" style="cursor: pointer;" class="cache-btn">List cached urls</button>
                    <button @click.stop="removeCatchedUrls" style="cursor: pointer;" class="cache-btn">Clear cache</button>
                </div>
            </div>
        </div>
        <hr style="background-color: #989898; border: none; height: 1px;">
        <div class="screen-info-center">
            <button @click.stop="openDeleteModal" style="cursor: pointer;" class="disconnect-btn">Disconnect this screen.</button>
        </div>
        
        <DeleteScreenModal
            v-if="showDeleteModal"
            @close="showDeleteModal = false"
            @delete="deleteScreen"
            @forceDelete="forceDeleteScreen"
            :currentScreen="screen"
        />
    </div>
</template>
<style>
 .screen-info-main{
    background-color: #171717; 
    color: white; 
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
 }
 .screen-info-top{
    display: flex;
    justify-content: space-between;
    align-items: start;
 }
 .screen-info-main img{
    width: 20px;
    height: 20px;
 }
 .screen-info-center{
    display: flex;
    flex-direction: column;
    gap: 12px;
 }
 .title{
    font-size: 10px;
    font-family: Inter;
    color: #989898;
 }
 .value{
    font-size: 12px;
    font-family: Inter;
    color: #c7c6c6;
    text-transform: capitalize;
 }
 .cache-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    background: #03d549af;
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.006em;
    color: #FFFFFF;
    outline: none;
    border: none;
 }
 .disconnect-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    background: #dbdbdb;
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.006em;
    color: #333;
    outline: none;
    border: none;
 }
</style>