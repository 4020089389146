<script>
import http from "../networkUtils/networkUtil";
import { usePlaylistStore } from "../store/PlaylistStore";


export default {
    name: "PlayerControls",
    props: ['togglePlayerInfo', 'togglePlayerControls', 'nextSlide', 'prevSlide', 'currentSlide', 'videoDuration'],
    mounted: function(){
        this.getPlaylistName();
    },
    data: function(){
        return{
            progressValue: 0,
            intervalId: null,
            duration: null,
            playlist: '',
        }
    },
    watch: {
        currentSlide: {
            immediate: true,
            handler(newSlide) {
                if (newSlide) {
                    // if (newSlide.type === "video") {
                    //     this.duration = this.videoDuration;
                    //     this.startProgress(this.duration);
                    //     console.log(this.videoDuration,"here")
                    // } else {
                        this.duration = newSlide.duration; 
                        this.startProgress(this.duration);
                    // }
                }
            },
        },
    },
    setup: function () {
        var playlistStore = usePlaylistStore();

        return {
            playlistStore: playlistStore,
        };
    },
    methods: {
        startProgress(duration) {
            this.progressValue = 0;

            if (this.intervalId) {
                clearInterval(this.intervalId);
            }

            let elapsed = 0;

            this.intervalId = setInterval(() => {
                elapsed += 100; 
                this.progressValue = elapsed;

                if (elapsed >= duration) {
                clearInterval(this.intervalId);
                }
            }, 100);
        },
        getPlaylistName: function() {
            var playlist = this.playlistStore.playlist.slides; 
            if (playlist && playlist.length > 0) {
                var playlistID = playlist[0].playlist_id;
                http.get(`/playlist/getname/${playlistID}`).then((data)=>{
                    this.playlist = data.data;
                })
            } else {
                console.log('Playlist is empty or not valid');
            }
        },
    },
    beforeUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },
}
</script>
<template>
    <div class="cntrls-main">
        <div class="cntrls-top-container">
            <div style="display: flex; flex-direction: column; gap: 4px;">
                <p class="cntrls-playlist-info">{{ playlist }}</p>
                <p class="cntrls-duration">00:18/01:19</p>
            </div>
            <div style="display: flex; gap: 5px;">
                <!-- <img src="../assets/volume-mute-fill.svg" alt=""> -->
                <img @click.stop="togglePlayerInfo()" style="cursor: pointer;" src="../assets/screen.svg" alt="">
                <img @click.stop="togglePlayerControls()" style="cursor: pointer;" src="../assets/close-line.svg" alt="">
            </div>
        </div>
        <div class="cntrls-center-container">
            <div style="display: flex; justify-content: center; align-items: center;">
                <img src="../assets/pause-line.svg" alt="">
            </div>
            <div style=" width: 100%; display: flex; justify-content: center; align-items: center;">
                <progress class="colored" style="width: 100%; height: 12px;" id="file" :value="progressValue" :max="duration || 100"></progress>
            </div>
        </div>
        <hr style="background-color: #989898; border: none; height: 1px;">
        <div class="cntrls-bottom-container">
            <div class="slide-type">
                <p style="text-transform: capitalize;">{{ currentSlide.type }}</p>
            </div>
            <div>
                <img @click.stop="prevSlide()" style="cursor: pointer;" src="../assets/skip-back-line.svg" alt="">
                <img @click.stop="nextSlide()" style="cursor: pointer;" src="../assets/skip-forward-line.svg" alt="">
            </div>
        </div>
    </div>
</template>
<style>
 .cntrls-main{
    background-color: #171717; 
    color: white; 
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: auto;
 }
 .cntrls-playlist-info{
    font-size: 12px;
    font-family: Inter;
    color: white;
 }
 .cntrls-duration{
    font-size: 10px;
    font-family: Inter;
    color: #989898;

 }
 .cntrls-top-container{
    display: flex;
    justify-content: space-between;
    align-items: start;
 }
 .cntrls-center-container{
    display: flex;
    align-items: center;
    justify-content: center;
 }
 .colored {
  accent-color: #03d548;
}
 .cntrls-bottom-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
 }
 .cntrls-main img{
    width: 20px;
    height: 20px;
 }
 .slide-type{
    font-size: 12px;
    font-family: Inter;
    color: #989898;
 }
</style>