<script>
import http from "../networkUtils/networkUtil";

export default {
  name: "DeleteScreenModal",
  props: {
    currentScreen: {
      type: Object,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    deleteScreen(id) {
      if (id) {
        http.delete(`/tv/screen/force-delete/${id}`).then(() => {
          this.$emit("delete");
        });
      } else {
        console.log("id not found")
        this.$emit("forceDelete");
      }     
    },
  },
};
</script>

<template>
    <div class="modal-backdrop" @click.self="close">
      <div class="modal" role="dialog">
        <div class="content">
            <div class="icon-box" style="margin: 16px 0px;">
                <img src="../assets/delete-bin-6-fill.svg" alt="">
            </div>
            <div class="playlist-name">
                <p>Delete '{{ currentScreen.name ? currentScreen.name : 'Untitled' }}'</p>
            </div>
            <div class="confirm-text pt-1">
                <p >Are you sure you want to delete the screen?</p>
            </div>
        </div>
        <hr style="width: 100%; border: 0.5px solid #E1E4EA;"/>
        <div style="padding: 20px; gap: 24px; display: flex;">
          <button @click="close" class="cancel">Cancel</button>
          <button @click="deleteScreen(currentScreen.id)" class="delete">Delete</button>
        </div>
      </div>
    </div>
</template>

<style>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px !important ;
    margin: 0px !important ;
    background: #ffffff;
    width: 440px;
    height: 232px;
    border: 1px solid #E1E4EA;
    box-shadow: 0px 16px 32px -12px rgba(14, 18, 27, 0.1);
    border-radius: 20px;
}

.content{
    margin-bottom: 20px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}
.icon-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;
    width: 40px;
    height: 40px;
    background: #FFEBEC;
    border-radius: 10px;
}
.playlist-name{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    color: #0E121B;
}
.confirm-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.006em;
    font-feature-settings: 'cv09' on, 'ss11' on, 'calt' off, 'liga' off;
    color: #525866;
}
.cancel{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 4px;
    width: 70px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #E1E4EA;
    box-shadow: 0px 1px 2px rgba(10, 13, 20, 0.03);
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    font-feature-settings: 'cv09' on, 'ss11' on, 'calt' off, 'liga' off;
    color: #525866;
    cursor: pointer;
}
.delete{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 4px;
    width: 67px;
    height: 36px;
    background: #FB3748;
    border: 1px solid #FB3748;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.006em;
    font-feature-settings: 'cv09' on, 'ss11' on, 'calt' off, 'liga' off;
    color: #FFFFFF;
    cursor: pointer;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity 0.3s ease;
}
</style>
