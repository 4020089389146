<script>
import { usePlaylistStore } from "../store/PlaylistStore";

export default {
  name: "DebugScreen",
  data() {
    return {
      isVisible: false, 
    };
  },
  setup: function () {
    var playlistStore = usePlaylistStore();

    return {
      playlistStore: playlistStore,
    };
  },
  methods: {
    clearLogs: function () {
        this.playlistStore.logs = [];
    },
    toggleModal: function () {
      this.isVisible = !this.isVisible;
    },
    clearCache: function(){
        if ('caches' in window) {
            caches.keys().then((cacheNames) => {
                cacheNames.forEach((cacheName) => {
                    caches.delete(cacheName);
                });
            });
        }
    },
  },
  computed: {
    toggleButtonStyle() {
      return {
        position: "fixed",
        bottom: "20px",
        right: "20px",
        backgroundColor: "#333333",
        color: "#FFF",
        border: "none",
        padding: "10px 10px",
        borderRadius: "5px",
        cursor: "pointer",
        zIndex: 1000,
      };
    },
    modalOverlayStyle() {
      return {
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1001,
      };
    },
    modalContentStyle() {
      return {
        width: "80%",
        maxHeight: "70%",
        backgroundColor: "#222",
        color: "#FFF",
        padding: "20px",
        borderRadius: "10px",
        overflowY: "auto",
        textAlign: "left",
      };
    },
    debugLogsStyle() {
      return {
        maxHeight: "300px",
        overflowY: "auto",
        whiteSpace: "pre-wrap",
        backgroundColor: "#333",
        color: "#FFF",
        padding: "10px",
        border: "1px solid #555",
        borderRadius: "5px",
        fontFamily: "monospace",
      };
    },
    buttonStyle() {
      return {
        padding: "8px 10px",
        backgroundColor: "#007BFF",
        color: "#FFF",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      };
    },
  },
};
</script>

<template>
    <div>
      <button :style="toggleButtonStyle" @click.stop="toggleModal">
        {{ isVisible ? 'Debug' : 'Debug' }}
      </button>
  
      <div v-if="isVisible" :style="modalOverlayStyle" @click="toggleModal">
        <div :style="modalContentStyle">
          <h4 style="margin: 0 0 20px; color: #ffffff;">Debug Logs</h4>
          <div v-for="(items, index) in this.playlistStore.logs" :key="index">
            <pre id="debug-logs" :style="debugLogsStyle">{{ items }}</pre>
          </div>
          <div style="margin-top: 10px; text-align: end;">
            <button :style="buttonStyle" @click="clearLogs" style="margin-left: 10px;">Clear Logs</button>
            <button :style="buttonStyle" @click="clearCache" style="margin-left: 10px;">Clear Cache</button>
          </div>
        </div>
      </div>
    </div>
</template>
  
